<template>
<div>
<b-row class="match-height">
    
    <b-col md="6" lg="6">
        <b-card title="Paso 1" sub-title="Digita la cantidad de dinero a invertir">
          <b-card-body class="card-body-bottom-0">    
          
            <cleave                          
                id="number1"
                v-model.number="valorAInvertir"
                value-field="valorAInvertir"
                class="form-control"
                :options="options.number" 
                placeholder="Digite"                           
                v-on:input="cantidadDineroAInvertirTyped"                          
                ref="myTextField"/>   
              
              <b-row v-if="parseFloat(valorAInvertir) < parseFloat(minimumParticipation) || valorAInvertir == null" class="mt-1 mb-0">
                <b-col>
                    <b-alert variant="warning" show>
                      <div class="alert-body">
                          <strong>Participación mínima para este proyecto ${{parseFloat(minimumParticipation).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</strong>
                      </div>
                    </b-alert>
                </b-col>
              </b-row>

              <b-row align-v="center">
                <b-col md="12" lg="12">  
                  <b-form-group class="mb-0">                      
                    <b v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">Total Kg comprados: {{parseFloat(this.totalKilograms).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kgs                     
                      <b-button id="popover-detalles-costos" variant="flat-primary" size="sm">(Ver Detalles)</b-button>
                    </b> 
                    <b v-if="project.projectType == 'Cria'">Total Sostycs comprados: {{parseFloat(this.totalUnits).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}                     
                      <b-button id="popover-detalles-costos" variant="flat-primary" size="sm">(Ver Detalles)</b-button>
                    </b>                     
                  </b-form-group>

                  <b-popover target="popover-detalles-costos" triggers="hover" no-fade>
                      <template #title>
                        Detalles
                      </template>
                      <div>                        
                        <b-row align-v="center" >
                          <b-col md="12" lg="12">  
                            <b-form-group>                      
                              <h6 v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">Precio por Kg: ${{parseFloat(project.totalPricePerKilogram).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</h6>                      
                              <h6 v-if="project.projectType == 'Cria'">Precio por Sostyc: ${{parseFloat(project.unitPrice).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</h6>                                         
                            </b-form-group>                  
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                              <b-alert variant="primary" show>
                                <div class="alert-body">
                                    <span class="font-small-1">Este valor incluye seguro animal, el transporte de los animales y la comisión de Sosty.                          
                                    </span>
                                </div>
                              </b-alert>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                              <b-alert variant="info" show v-if="project.projectType == 'Cria'">
                                <div class="alert-body">
                                    <span class="font-small-1">Un Sostyc es la unidad de medida equivalente de participación dentro del proyecto, para este proyecto se necesitan un total de   {{parseFloat(project.totalUnits).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs                             
                                    </span>
                                </div>
                              </b-alert>
                          </b-col>
                        </b-row>
                      </div>
                  </b-popover>                     
                </b-col>
              </b-row>    
            
          </b-card-body>
        </b-card>

        <b-card title="Paso 2" sub-title="Actualiza tu información de NeoGanadero">  
          <b-card-body class="card-body-bottom-0">
            <b-row v-if="investorNeedsUpdate">
                <b-col md="12" xl="12" class="mb-2">
                    <b-form-checkbox v-model="investorIsCompany"  checked="false" name="check-button" switch inline> Eres Persona Jurídica (Empresa)? </b-form-checkbox>
                </b-col> 
            </b-row>

            <b-row v-if="!investorIsCompany && investorNeedsUpdate">
              <b-col md="6" xl="6">
                  <b-form-group label="Nombre(s)" label-for="basicInput">
                  <b-form-input v-model="investorFirstName" placeholder="Digite"/>
                  </b-form-group>
              </b-col>                
              <b-col md="6" xl="6">
                  <b-form-group label="Apellido(s)" label-for="basicInput">
                  <b-form-input v-model="investorLastName" placeholder="Digite"/>
                  </b-form-group>
              </b-col> 
              <b-col md="6" xl="6">
                  <b-form-group label="Tipo de Documento" label-for="basicInput">                        
                  <b-form-select v-model="investorDocumentType" :options="documentTypeOptions" placeholder="Seleccione"/>
                  </b-form-group>
              </b-col> 
              <b-col md="6" xl="6">
                  <b-form-group label="Numero de Documento" label-for="basicInput">
                  <b-form-input v-model="investorDocumentNumber" placeholder="Digite"/>
                  </b-form-group>
              </b-col>  
              <b-col md="6" xl="6">
                  <b-form-group label="Telefono Celular" label-for="basicInput">
                  <b-form-input v-model="investorPhone" placeholder="Digite"/>
                  </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                  <b-form-group label="Dirección" label-for="basicInput">
                  <b-form-input v-model="investorAddress" placeholder="Digite"/>
                  </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                  <b-form-group label="Departamento" label-for="basicInput">                        
                      <b-form-select @change="onDepartamentoSelect" v-model="investorState" :options="departamentosList" value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                  </b-form-group>
              </b-col>  
              <b-col md="6" xl="6">
                  <b-form-group label="Municipio/Ciudad" label-for="basicInput">                        
                      <b-form-select v-model="investorCity" :options="municipiosList" placeholder="Seleccione"/>
                  </b-form-group>
              </b-col> 
            </b-row>
            
            <b-row v-if="investorIsCompany  && investorNeedsUpdate">
              <b-col md="6" xl="6">
                  <b-form-group label="Razón Social" label-for="basicInput">
                  <b-form-input v-model="investorCompanyName" placeholder="Digite"/>
                  </b-form-group>
              </b-col>                
              <b-col md="6" xl="6">
                  <b-form-group label="NIT" label-for="basicInput">
                  <b-form-input v-model="investorCompanyNit" placeholder="Digite"/>
                  </b-form-group>
              </b-col> 
              <b-col md="6" xl="6">
                  <b-form-group label="Nombre(s) Rep Legal" label-for="basicInput">
                  <b-form-input v-model="investorFirstName" placeholder="Digite"/>
                  </b-form-group>
              </b-col>                
              <b-col md="6" xl="6">
                  <b-form-group label="Apellido(s) Rep Legal" label-for="basicInput">
                  <b-form-input v-model="investorLastName" placeholder="Digite"/>
                  </b-form-group>
              </b-col> 
              <b-col md="6" xl="6">
                  <b-form-group label="Tipo de Documento Rep Legal" label-for="basicInput">                        
                  <b-form-select v-model="investorDocumentType" :options="documentTypeOptions" placeholder="Seleccione"/>
                  </b-form-group>
              </b-col> 
              <b-col md="6" xl="6">
                  <b-form-group label="Numero de Documento Rep Legal" label-for="basicInput">
                  <b-form-input v-model="investorDocumentNumber" placeholder="Digite"/>
                  </b-form-group>
              </b-col>  
              <b-col md="6" xl="6">
                  <b-form-group label="Telefono Celular Rep Legal" label-for="basicInput">
                  <b-form-input v-model="investorPhone" placeholder="Digite"/>
                  </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                  <b-form-group label="Dirección" label-for="basicInput">
                  <b-form-input v-model="investorAddress" placeholder="Digite"/>
                  </b-form-group>
              </b-col>
              <b-col md="6" xl="6">
                  <b-form-group label="Departamento" label-for="basicInput">                        
                      <b-form-select @change="onDepartamentoSelect" v-model="investorState" :options="departamentosList" value-field="departamento" text-field="departamento" placeholder="Seleccione"/>
                  </b-form-group>
              </b-col>  
              <b-col md="6" xl="6">
                  <b-form-group label="Municipio/Ciudad" label-for="basicInput">                        
                      <b-form-select v-model="investorCity" :options="municipiosList" placeholder="Seleccione"/>
                  </b-form-group>
              </b-col> 
            </b-row>

            <b-row v-if="!investorNeedsUpdate">      
              <b-col md="12" lg="12">
                  <b-alert variant="info" class="mb-0" show>
                    <div class="alert-body font-small-1">
                      <span><strong>Tus datos están actualizados, si deseas modificarlos dirígete a la opción "Información Neoganadero" en el menú</strong></span>
                    </div>
                  </b-alert>
              </b-col>
            </b-row>
            
          </b-card-body>    
                    
        </b-card>

        <b-card title="Paso 3" sub-title="Revisa y Acepta el Contrato de Colaboración">    
          <b-card-body class="card-body-bottom-0">
            <b-form-checkbox v-model="contratoColaboracionAccepted" @change="contractAccepted()"  checked="false" name="check-button" switch inline> 
              Acepto las condiciones del contrato de Colaboración
              <b-card-text style="color:#008D36; display:inline" @click="openTemplateDocument('contratoColaboracionFile')" class="font-small-3 sosty-pointer primary">
                (Descargar Contrato)
              </b-card-text>
            </b-form-checkbox>     
          </b-card-body>    
        </b-card>

        <b-card title="Paso 4" sub-title="Realiza el pago a través de uno de los siguientes medios" >    
          <b-card-body class="card-body-bottom-0">
            <b-tabs>
              <b-tab active title="PSE">
                <b-col class="cursor-pointer" @click="openPSELink()">
                        <b-avatar-group class="mt-1 pt-50" size="60">
                          <h6 class="align-self-center mb-0">
                            <b-avatar src="https://www.homecenter.com.co/static/landing/PSE/img/PSE.png" class="pull-up"/>
                            <p style="display:inline; margin-left:18px">Click aquí para pagar.</p>
                            
                          </h6>
                        </b-avatar-group>
                      </b-col>
              </b-tab>
              <b-tab title="Consignación">
                <b-col>
                    <b-form-group label-for="basicInput">
                        <p>Realiza el pago y adjunta el comprobante de consignación
                          <b-card-text style="color:#008D36" class="font-small-1 mb-0">
                            SOSTY S.A.S, NIT: 901466394-5, Bancolombia cuenta de ahorros 291-000020-57,
                          </b-card-text>
                        </p>
                        <b-form-file placeholder="Seleccione un archivo" v-model="paymentProofFile" />
                        <b-card-text @click="openDocument(paymentProofUrl)" accept="image/*" v-if="paymentProofUrl" class="cursor-pointer sosty-font-smaller">abrir <feather-icon icon="ArrowDownCircleIcon" /></b-card-text>
                    </b-form-group>
                </b-col>             
              </b-tab>             
            </b-tabs>
            <b-alert variant="warning" show class="mt-1">
              <div class="alert-body">                  
                    Despues de pagar, debes volver a esta ventana y <strong>CONFIRMAR TU INVERSIÓN EN EL PASO 5</strong>                  
              </div>
            </b-alert>
          </b-card-body>    
        </b-card>

        <b-card title="Paso 5" sub-title="Confirma tu Inversión" >
            <b-card-body class="card-body-bottom-0">
                  <b-row class="mb-1">
                      <b-col v-if="!processing" md="12" lg="12">
                          <b-button size="lg" variant="primary" block @click="ConfirmInvestment">Confirmar Inversión</b-button>
                      </b-col>
                      <b-col v-else-if="processing" md="12" lg="12">
                          <b-button size="lg" variant="primary" disabled block><b-spinner small label="Procesando..." /></b-button>
                      </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                        <b-alert variant="info" show>
                          <div class="alert-body font-small-1">
                              <strong>
                                *El equipo de SOSTY validará y aprobará su Participación en un plazo máximo de 24 horas
                              </strong>
                              <br><br>
                              <strong>
                                *Sosty cobrará una comisión del 10% sobre la utilidad bruta del proyecto por realizar el acompañamiento hasta su liquidación.
                              </strong>
                              <br><br>
                              <strong>
                                *El productor cobrará un {{project.producerCommission}} de comisión sobre la utilidad bruta.
                              </strong>
                          </div>
                        </b-alert>
                    </b-col>
                  </b-row>  
            </b-card-body>
        </b-card>
    </b-col>

    <b-col md="6" lg="6">
      <!-- <b-card :title="project.projectName + ' (' + project.projectCode + ')'" :sub-title="project.locationAddress"> -->      
      <b-card >        
        <b-card-title>
          <b-row class="mb-0">
            <b-col>
                <h3>{{project.projectName}} ({{project.projectCode}})</h3>
                <h6 style="opacity:0.6">Ubicación: {{project.locationAddress}} </h6>
            </b-col>
            <b-col> 
                <b-row style="align-items:center;">  
                    <b-col style="text-align:right; padding-right:25px">
                        <h6 style="font-size:12px; color:#008D36;">Póliza de seguro
                            <b-avatar size="24" variant="light-primary">
                                <feather-icon size="18" icon="ShieldIcon"/>
                            </b-avatar>  
                        </h6>                              
                    </b-col>
                </b-row>

                <b-row style="align-items:center;">
                    <b-col style="text-align:right;padding-right:25px">                                     
                        <h6 style="font-size:12px; color:#008D36;">Monitoreo Sosty
                            <b-avatar  size="24" variant="light-primary">
                                <feather-icon size="18" icon="FileIcon"/>
                            </b-avatar>   
                        </h6>                    
                    </b-col>         
                </b-row>
            </b-col>
          </b-row>
        </b-card-title>


      <b-card-body class="statistics-body">        
        <b-row>
            <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="BarChartIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{(project.projectProfitability)}} % (E.A)</h4>
                    <b-card-text class="font-small-3 mb-0">Rentabilidad Estimada*</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="UsersIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{project.amountOfInvestors}}</h4>
                    <b-card-text class="font-small-3 mb-0">NeoGanaderos</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="DollarSignIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">${{parseFloat(this.minimalInvestment).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</h4>
                    <b-card-text class="font-small-3 mb-0">Inversión mínima</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="CalendarIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{project.daysLeft}} días</h4>
                    <b-card-text class="font-small-3 mb-0">Finalización Recaudo</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="CalendarIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">{{project.projectDuration}} Meses</h4>
                    <b-card-text class="font-small-3 mb-0">Duración</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
            <b-col md="6" lg="6" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0 mt-1">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="TagIcon"/>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">{{project.amountOfCattles}}</h4>
                    <b-card-text class="font-small-3 mb-0" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">Cantidad de Animales</b-card-text>
                    <h4 class="font-weight-bolder mb-0" v-if="project.projectType == 'Cria'">{{project.amountOfBulls}} - {{project.amountOfCows}}</h4>
                    <b-card-text class="font-small-3 mb-0" v-if="project.projectType == 'Cria'">Toros - Vacas</b-card-text>
                  </b-media-body>
                </b-media>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12" lg="12">
                <b-row class="mt-4" v-if="project.projectType == 'Levante' || project.projectType == 'Ceba'">
                    <b-col md="6" lg="6">
                        <b-card-text class="mb-50"><b>Actual:</b> {{currentAmountOfCattles}} Animales ({{parseFloat(project.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>
                    </b-col>
                    <b-col md="6" lg="6" class="text-right">
                        <b-card-text class="mb-50"><b>Meta:</b> {{project.amountOfCattles}} Animales ({{parseFloat(project.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg)</b-card-text>
                    </b-col>
                </b-row>

                <b-row class="mt-2" v-if="project.projectType == 'Cria'">
                    <b-col md="6" lg="6">
                        <b-card-text class="mb-50"><b>Actual: </b>{{parseFloat(project.investmentCollected).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>
                    </b-col>
                    <b-col md="6" lg="6" class="text-right">
                        <b-card-text class="mb-50"><b>Meta: </b>{{parseFloat(project.investmentRequired).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Sostycs</b-card-text>
                    </b-col>
                </b-row>
                
                <b-progress class="mb-50" animated v-if="this.minBar>5 && (project.projectType == 'Levante' || project.projectType == 'Ceba')" striped :max="max" height="20px">
                  <b-progress-bar animated variant="primary" :value="project.projectProgres"></b-progress-bar>                  
                  <b-progress-bar animated variant="warning" :value="this.minBar" >min</b-progress-bar>                  
                </b-progress>

                <b-progress class="mb-50" animated v-else-if="this.minBar > 0 && this.minBar <= 5 && (project.projectType == 'Levante' || project.projectType == 'Ceba')" striped :max="max" height="20px">
                  <b-progress-bar animated variant="primary" :value="project.projectProgres"></b-progress-bar>                  
                  <b-progress-bar animated variant="warning" :value="this.minBar" ></b-progress-bar>                  
                </b-progress>

                <b-progress class="mb-50" animated v-else striped :max="max" height="20px">
                  <b-progress-bar animated variant="primary" :value="project.projectProgres"></b-progress-bar>
                </b-progress>  
            </b-col>
        </b-row>

        <b-row v-if="this.project.projectProgres < this.minRequiredToStart && (project.projectType == 'Levante' || project.projectType == 'Ceba')">      
          <b-col md="12" lg="12">
              <b-alert variant="info" class="mb-0" show>
                <div style="cursor:pointer;" b-tooltip.hover title="En caso de no completar el mínimo, te devolvemos el dinero" class="alert-body font-small-1">
                  <span style="font-size:14px" ><strong>Ayúdanos a completar el mínimo para comenzar el proyecto</strong></span>
                </div>
              </b-alert>
          </b-col>
        </b-row>        
        <!-- <b-row>
            <b-col>
                <b-alert variant="warning" show>
                <div class="alert-body">
                    <span><strong>Si no puedes completar el proceso en este momento, puedes guardar y adjuntar los documentos mas tarde.</strong></span>
                </div>
                </b-alert>
            </b-col>
        </b-row> -->
      </b-card-body>
      </b-card>
    </b-col>

  </b-row>

  <b-modal id ="loading-checkId" hide-footer ref="loading-checkId" modal-class="modal-primary" centered title="Verificando...">
    <b-col md="12" xl="12" class="mb-1">
      <b-form-group>                                          
        <h4 style="color:#008D36">Un momento por favor, estamos verificando tus antecedentes</h4>        
      </b-form-group>
      <b-spinner class="justify-content-center" label="Large Spinner"></b-spinner>
    </b-col>
  </b-modal>

</div>
    
  
</template>

<script>
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  BListGroup, BListGroupItem, BCard, BBadge, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BProgress, BMedia, BAvatar, BAvatarGroup, BMediaAside, BMediaBody, BEmbed, BCardHeader, BSpinner,
   BFormInput, BFormGroup, BFormDatepicker, BFormTextArea, BFormFile, BFormRadio, BAlert, BFormCheckbox, BInputGroup, BModal, VBPopover, BPopover, BTabs, BTab, BFormSelect, BProgressBar, BTooltip,
   } from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import BCardCode from '@core/components/b-card-code'
import departamentos from '../../assets/departamentos-municipios.json'

export default {
  components: {
    BCard,
    BBadge,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BFormCheckbox,
    BFormSelect,
    BRow,
    BInputGroup,
    BCol,
    BLink,
    BImg,
    BProgress,
    BProgressBar,
    StatisticCardHorizontal,
    StatisticCardVertical,
    BMedia,
    BAvatar,
    BAvatarGroup,
    BMediaAside,
    BMediaBody,
    BCardCode,
    BModal,  
    BEmbed,
    BFormRadio,
    BTooltip,
    BListGroup, BListGroupItem,    
    BFormInput, BFormGroup, BFormDatepicker, BFormTextArea, BFormFile, Cleave, BAlert, BSpinner, BPopover, BTabs, BTab,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      documentTypeOptions: [
          { value: 'Cédula', text: 'Cédula' },
          { value: 'Cédula de Extranjería', text: 'Cédula de Extranjería' },
          { value: 'Pasaporte Nacional', text: 'Pasaporte Nacional' },
          { value: 'Pasaporte Extranjero', text: 'Pasaporte Extranjero' },
      ],
      minimumAmountToInvest:null,  
      amountToInvest:0,
      processing: false,
      projectID: null,
      projectCode: null,
      investor: null,
      project: null,
      paymentProofFile: null,
      paymentProofUrl: null,
      contratoColaboracionFile: null,
      contratoColaboracionUrl: null,
      contratoMandatoFile: null,
      contratoMandatoUrl: null,
      totalProjectWeigthGain: null,  
      pesoFinal: null,    
      currentAmountOfCattles: null,
      minimumParticipation : null,
      kilogramVal: null,
      freightPerKilogram: null,
      insuranceValuePerKilogram: null,
      sostyCommission: null,
      totalPricePerKilogram: null,
      kiloBase: null,      
      NumeroKilogramosComprar: null,  
      valorAInvertir: null,
      totalCattles: null,
      totalKilograms: 0, 
      totalUnits: 0,     
      simulateTotalToInvest: null, 
      tasaSostyComisionMandato: '0',  
      totalToPay: '0',
      projectHaveReachedInvestment: false,
      checkedPersonaNatural: false, 
      checkedEmpresa: false, 
      numeroDeCedulaDigitado: null,
      numeroDeNITDigitado: null,
      numeroDocumetoDigitado: null,      
      minScore: 0.8,
      score: null,
      hideCedula: false,
      contratoColaboracionAccepted: false,
      tipo: true,  
      user: null,
      mandatoPercentage: null,  
      totalPriceOfKilograms:0,  
      minimalInvestment:700000,
      investorIsCompany: false,
      investorFirstName: null,
      investorLastName: null,
      investorDocumentType: null,
      investorDocumentNumber: null,   
      investorPhone: null,
      investorAddress: null, 
      investorState: null, 
      investorCity: null,  
      investorCompanyName: null,  
      investorCompanyNit: null,  
      departamentosList: departamentos,
      municipiosList: [],
      investorNeedsUpdate: false,
      max: 100,
      minBar: 0,
      minRequiredToStart: 0,
      minKgsRequested: 0,
      minCattlesRequired:25,
    }
  },
  created() {  
    if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
      this.projectID = this.$route.query.projectID;
      this.GetProjectPublicById();      
    } else if(this.$route != null && this.$route.query != null && this.$route.query.projectCode != null) {
      this.projectCode = this.$route.query.projectCode;
      this.GetProjectPublicByCode();
    } 
    var userData = getUserData();
    if(userData) {
      this.investor = userData;      
    }
    this.GetUserById(); 
    this.GetInvestorProfileDetails(); 
    this.UpdateActiveCampaignDeal(0, '9')    
  },
   watch:{
      paymentProofFile: function(val) { this.paymentProofFile = val; this.uploadFile(val, 'paymentProofFile'); },  
      contratoColaboracionFile: function(val) { this.contratoColaboracionFile = val; this.uploadFile(val, 'contratoColaboracionFile'); }, 
      contratoMandatoFile: function(val) { this.contratoMandatoFile = val; this.uploadFile(val, 'contratoMandatoFile'); }, 
      valorAInvertir: function(val) { this.valorAInvertir = val; }, 
      numeroDeCedulaDigitado: function(val) {this.numeroDeCedulaDigitado = val; },    
      numeroDeNITDigitado: function(val) {this.numeroDeNITDigitado = val; },
      score: function(val) {this.score = val; }, 
      hideCedula: function (val) {this.hideCedula = val;},
      checkedPersonaNatural: function (val) { this.checkedPersonaNatural = val;},
      checkedEmpresa: function (val) { this.checkedEmpresa = val;},
      minBar: function(val) { this.minBar = val; },

  },
  methods: {
    GetProjectPublicById() {
        this.processing = true;
        axios.get('/Project/GetProjectPublicById', { params: { projectID: this.projectID }})
        .then(response => {
          this.processing = false;
          this.project = response.data;

          this.project.projectProgres = parseFloat(this.project.projectProgres)
          this.minKgsRequested = parseFloat(this.project.investmentRequired)*parseFloat(this.minCattlesRequired)/100                    
          this.minRequiredToStart = (parseFloat(parseFloat(this.minKgsRequested))*100/Math.round(parseFloat(this.project.investmentRequired)).toFixed(2))          
          this.minBar = parseFloat(this.minRequiredToStart).toFixed(2) - parseFloat((this.project.projectProgres));
          
          this.calculateMinimumParticipationProjectType()           
          this.variables()
          this.calculateCurrentAmountOfCattles()
          this.calculateTotalProjectWeigthGain()           

          var amountOfInvestors = 19 - parseFloat(this.project.amountOfInvestors);
          var remainingAmount = parseFloat(this.project.investmentRequired) - parseFloat(this.project.investmentCollected);
          this.minimumAmountToInvest = Math.round(remainingAmount/amountOfInvestors);

          if(!this.project.mandatoPercentage) this.project.mandatoPercentage = 2
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    GetProjectPublicByCode() {
        this.processing = true;
        axios.get('/Project/GetProjectPublicByCode', { params: { projectCode: this.projectCode }})
        .then(response => {
          this.processing = false;
          this.project = response.data;

          this.project.projectProgres = parseFloat(this.project.projectProgres)
          this.minKgsRequested = parseFloat(this.project.investmentRequired)*parseFloat(this.minCattlesRequired)/100                    
          this.minRequiredToStart = (parseFloat(parseFloat(this.minKgsRequested))*100/Math.round(parseFloat(this.project.investmentRequired)).toFixed(2))          
          this.minBar = parseFloat(this.minRequiredToStart).toFixed(2) - parseFloat((this.project.projectProgres));
          
          this.calculateMinimumParticipationProjectType()           
          this.variables()
          this.calculateCurrentAmountOfCattles()
          this.calculateTotalProjectWeigthGain()           

          var amountOfInvestors = 19 - parseFloat(this.project.amountOfInvestors);
          var remainingAmount = parseFloat(this.project.investmentRequired) - parseFloat(this.project.investmentCollected);
          this.minimumAmountToInvest = Math.round(remainingAmount/amountOfInvestors);

          if(!this.project.mandatoPercentage) this.project.mandatoPercentage = 2
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    GetUserById(){
        this.processing = true;        
        axios.get('/User/GetUserbyID', { params: { userID: this.investor.userID}})
        .then(response => {
          this.processing = false;
          this.user = response.data;
        })
    },
    GetInvestorProfileDetails(){
        this.processing = true;        
        axios.get('/User/GetProfileDetails', { params: { userID: this.investor.userID, userType: 'Inversionista'}})
        .then(response => {;
          var profileDetails = response.data;
          this.investorIsCompany = profileDetails.isCompany === true;
          this.investorFirstName = profileDetails.firstName;
          this.investorLastName = profileDetails.lastName;
          this.investorDocumentType = profileDetails.documentType;
          this.investorDocumentNumber = profileDetails.documentNumber;
          this.investorPhone = profileDetails.contactCellphone;
          this.investorAddress = profileDetails.address;
          this.investorState = profileDetails.state;
          this.investorCity = profileDetails.city;
          this.investorCompanyName = profileDetails.companyName;
          this.investorCompanyNit = profileDetails.nit;

          if(this.investorIsCompany === true){
            if( !this.investorFirstName || 
                !this.investorLastName || 
                !this.investorDocumentType || 
                !this.investorDocumentNumber || 
                !this.investorPhone || 
                !this.investorAddress || 
                !this.investorState || 
                !this.investorCity ||  
                !this.investorCompanyName ||
                !this.investorCompanyNit)
                this.investorNeedsUpdate = true;

          }else{
              if( !this.investorFirstName || 
                  !this.investorLastName || 
                  !this.investorDocumentType || 
                  !this.investorDocumentNumber || 
                  !this.investorPhone || 
                  !this.investorAddress || 
                  !this.investorState || 
                  !this.investorCity)
                this.investorNeedsUpdate = true;
          }

        })
    },
    viewProjectPublicDetails() {
      this.$router.replace('/public-project-details?projectCode=' + this.project.projectCode);
    },
    ConfirmInvestment() {
        if(this.investorIsCompany){
            if( !this.investorFirstName || 
                !this.investorLastName || 
                !this.investorDocumentType || 
                !this.investorDocumentNumber || 
                !this.investorPhone || 
                !this.investorAddress || 
                !this.investorState || 
                !this.investorCity ||  
                !this.investorCompanyName ||
                !this.investorCompanyNit){
                  this.$bvToast.toast("Debes actualizar toda la información del paso 2", { title: 'Error', variant: 'danger', solid: true, });
                  return;
                }                

          }else{
              if( !this.investorFirstName || 
                  !this.investorLastName || 
                  !this.investorDocumentType || 
                  !this.investorDocumentNumber || 
                  !this.investorPhone || 
                  !this.investorAddress || 
                  !this.investorState || 
                  !this.investorCity){
                    this.$bvToast.toast("Debes actualizar toda la información del paso 2", { title: 'Error', variant: 'danger', solid: true, });
                    return;
                  }
          }

        if(this.valorAInvertir < 700000){
          this.$bvToast.toast("La participación mínima es $700.000 COP.", { title: 'Error', variant: 'danger', solid: true, });
          return;
        } 

        if(!this.contratoColaboracionAccepted){
          this.$bvToast.toast("Debes aceptar el contrato de colaboración", { title: 'Error', variant: 'danger', solid: true, });
          return;
        }

        var payload = {
            investorID: this.investor.userID, 
            projectID: this.project.projectID, 
            amountToInvest: this.amountToInvest, 
            paymentProofUrl: this.paymentProofUrl, 
            totalKilograms: parseFloat(this.totalKilograms), 
            totalCattles: parseFloat(this.totalCattles),
            contratoColaboracionUrl: this.contratoColaboracionUrl,
            contratoMandatoUrl: this.contratoMandatoUrl,
            investorNeedsUpdate: this.investorNeedsUpdate,
            investorIsCompany: this.investorIsCompany, 
            investorFirstName: this.investorFirstName,
            investorLastName: this.investorLastName,
            investorDocumentType: this.investorDocumentType,
            investorDocumentNumber: this.investorDocumentNumber,
            investorPhone: this.investorPhone,
            investorAddress: this.investorAddress,
            investorState: this.investorState,
            investorCity: this.investorCity,
            investorCompanyName: this.investorCompanyName,
            investorCompanyNit: this.investorCompanyNit,
            validateIfProjectIsFull: false,
            totalUnits: parseFloat(this.totalUnits),
        };

        this.processing = true;
        axios.post('/Investment/CreateInvestment', payload)
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.UpdateActiveCampaignDeal(0, '6', Math.round(this.amountToInvest))
            this.$bvToast.toast("Información enviada de forma exitosa, nuestro equipo se pondra en contacto.", { title: 'Ok', variant: 'success', solid: true, });            
            this.$router.push('profile-definition?userID=' + payload.investorID);
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Error', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })        
    },
    uploadFile(fileToUpload, fileType) {
        this.processing = true;               
        let formData = new FormData();
        formData.append('file', fileToUpload);
        axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.processing = false;
          if(response.data) {
            this.setFileUrl(fileType, response.data);
            this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
          } else {
            this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
          }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })
    },
    setFileUrl(fileType, fileUrl) {
        switch(fileType) {
            case 'paymentProofFile':
                this.UpdateActiveCampaignDeal(0, '11')
                this.paymentProofUrl = fileUrl;
                break;
            case 'contratoColaboracionFile':
                this.contratoColaboracionUrl = fileUrl;
                break;
            case 'contratoMandatoFile':
                this.contratoMandatoUrl = fileUrl;
                break;
        }
    },
    openDocument(fileUrl) {
        window.open(fileUrl, '_blank');
    },
    openTemplateDocument(fileType) {
        var fileUrl = '';
        switch(fileType) {            
            case 'contratoColaboracionFile':
                fileUrl = this.project.contratoColaboracionUrl;
                break;
            case 'contratoMandatoFile':
                fileUrl = 'https://drive.google.com/file/d/13sMx_oxQKMzztJy17-rPwmhUOH0wRoAu/view?usp=sharing';
                break;
        }
        window.open(fileUrl, '_blank');
    },
    calculateTotalProjectWeigthGain(){
      var tipoProyecto = this.project.projectType
      this.pesoFinal = parseFloat(this.project.finalWeight)      
       
      if(this.project.amountOfCattles && this.pesoFinal){
        var a = parseFloat(this.project.amountOfCattles) * parseFloat(this.pesoFinal)
        this.totalProjectWeigthGain = a  
        this.project.totalBatchWeight = parseInt(this.pesoFinal)      
      }else{
        this.pesoFinal = 0
        this.totalProjectWeigthGain = 0
      }
    },
    calculateCurrentAmountOfCattles(){   
      if(this.project.investmentRequired != 0){
        var c = (parseFloat(this.project.investmentCollected) * parseFloat(this.project.amountOfCattles) / parseFloat(this.project.investmentRequired))        
        this.currentAmountOfCattles = parseFloat(c).toFixed(2)        
      }else{
        this.currentAmountOfCattles = 0;
        c = 0
      }
    },
    calculateMinimumParticipationProjectType(){      
      var tipoProyecto = this.project.projectType   
      this.minimumParticipation = 700000;
      // this.valorAInvertir = this.minimumParticipation;
      this.valorAInvertir = null,
      this.kiloBase = this.project.initialWeight;
    },
    variables(){
      this.kilogramVal = this.project.initialKilogramPrice
      if(!this.kilogramVal) this.kilogramVal = 0;  
      this.kilogramValVenta = this.project.finalKilogramPrice;
      var a = (parseFloat(this.project.estimatedFreightCost)).toFixed(2)
      this.freightPerKilogram = parseFloat(a)   
      if(!this.freightPerKilogram) this.freightPerKilogram = 0;
      a = 0  
      this.insuranceValuePerKilogram = this.project.insurancePricePerKilogram;
      if(!this.insuranceValuePerKilogram) this.insuranceValuePerKilogram  = 0
      this.sostyCommission = parseFloat((this.kilogramVal + this.freightPerKilogram + this.insuranceValuePerKilogram) * this.project.sostyComission/100)
      if(!this.sostyCommission) this.sostyCommission = 0;
      var b = (this.kilogramVal + this.freightPerKilogram + this.insuranceValuePerKilogram + this.sostyCommission).toFixed(2)   
      this.totalPricePerKilogram = parseFloat(b)
      if(!this.totalPricePerKilogram) this.totalPricePerKilogram = 0;      
      b = 0         
      this.projectHaveReachedInvestment = parseFloat(this.project.investmentCollected) >= parseFloat(this.project.investmentRequired);
    },

    cantidadDineroAInvertirTyped(event){
      //valorAInvertir      
      this.valorAInvertir = parseFloat(event)      

      if(this.valorAInvertir){
        this.amountToInvest = parseFloat(this.valorAInvertir)
      }else{
        this.valorAInvertir = 0
        this.amountToInvest = 0
      }
      if(this.project.projectType == 'Levante' || this.project.projectType == 'Ceba') {
        //totalPagadoPorKilos
        this.totalPriceOfKilograms = parseFloat((this.amountToInvest)/(parseFloat(1 + (parseFloat(this.project.mandatoPercentage/100) + parseFloat(this.project.mandatoPercentage/100 * 5/100))))).toFixed(2)      
        //Calculo TasaSostyComision     
        //P1
        var amountInvestPercentage1 = parseFloat(this.totalPriceOfKilograms * this.project.mandatoPercentage/100).toFixed(2)
        //P2
        var amountInvestPercentage2 = parseFloat(this.totalPriceOfKilograms * this.project.mandatoPercentage/100 * 5/100).toFixed(2)
        //tasaSostyComisionMandato
        this.tasaSostyComisionMandato = parseFloat(this.totalPriceOfKilograms*(this.project.mandatoPercentage/100 + (this.project.mandatoPercentage/100 * 5/100))).toFixed(2)      
        //prueba inversión
        var total = parseFloat(amountInvestPercentage2) + parseFloat(amountInvestPercentage1) + parseFloat(this.totalPriceOfKilograms)
        //numeroKilogramosComprar
        if(this.totalPriceOfKilograms){
          this.NumeroKilogramosComprar = parseFloat(this.amountToInvest)/parseFloat(this.project.totalPricePerKilogram)
          // this.totalKilograms = parseFloat(this.NumeroKilogramosComprar).toFixed(2)
          this.totalKilograms = parseFloat(this.NumeroKilogramosComprar).toFixed(2)
        }else{
          this.totalKilograms = 0
        }

        this.calculateAmountOfCattles()
      } else if(this.project.projectType == 'Cria') {
        this.totalUnits = parseFloat(this.valorAInvertir/this.project.unitPrice).toFixed(2);
      } 
      
    },
    calculateAmountOfCattles(){
      if(this.NumeroKilogramosComprar && this.kiloBase){
        var d = (parseFloat(this.NumeroKilogramosComprar) / parseFloat(this.kiloBase))
        this.totalCattles = parseFloat(d).toFixed(2)
      }else{
        this.totalCattles = 0
      }       
    },
    openPSELink(){      
      this.UpdateActiveCampaignDeal(0, '10')
      window.open("https://www.psepagos.co/PSEHostingUI/ShowTicketOffice.aspx?ID=10827", '_blank');
    },
    PersonaType(){
      if(this.checkedPersonaNatural){        
        this.checkedEmpresa = false
        this.tipo = true
      }
    },
    EmpresaType(){
      if(this.checkedEmpresa){
        this.checkedPersonaNatural = false
        this.tipo = false
      }
    },
    DocumentTyped(event){
      if(this.checkedPersonaNatural){
        this.numeroDeCedulaDigitado = parseFloat(event)
        this.numeroDocumentoDigitado = this.numeroDeCedulaDigitado
        return;
      }
      
      if(this.checkedEmpresa){
        this.numeroDeNITDigitado = parseFloat(event)
        this.numeroDocumentoDigitado = this.numeroDeNITDigitado
        return;
      }      
    },
    onDepartamentoSelect(event){
        this.locationState = event;        
        var departamento = this.departamentosList.find(x => x.departamento === event);
        this.municipiosList = departamento.ciudades;
    }, 
    contractAccepted(){
      this.UpdateActiveCampaignDeal(0, '12')
    },
    UpdateActiveCampaignDeal(status, stage, value){
        var userData = getUserData();
        if(userData) axios.get('/User/UpdateActiveCampaignDeal', { params: { userID: userData.userID, status: status, stage: stage, title: this.project.projectCode, value: value } })
    } 
  }  
}
</script>
